import React, { useState } from "react";

export default function ImageGallery({ images }) {
    const [hoverIndex, setHoverIndex] = useState(null);

    return (
        <div className="image-gallery flex flex-wrap my-8 items-center justify-center">
            {images.map((image, index) => (
                <div
                    className={
                        "image-gallery__item " +
                        (hoverIndex === null || hoverIndex === index
                            ? "opacity-100"
                            : "opacity-50")
                    }
                    key={index}
                    onMouseOver={() => setHoverIndex(index)}
                >
                    <a
                        href={`/create?prompt=${encodeURI(image.alt)}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={image.src}
                            alt={image.alt}
                            className="border-0 no-underline w-32 rounded-sm object-cover"
                        />
                    </a>
                </div>
            ))}
            {hoverIndex !== null && (
                <div className="block p-4 rounded-md bg-gray-100 mx-4 mt-4 text-sm image-gallery__caption text-gray-700">
                    &quot;{images[hoverIndex]?.alt ?? ""}&quot;
                </div>
            )}
        </div>
    );
}
