import { CheckBadgeIcon } from "@heroicons/react/20/solid";

export default function Component(props) {
    return (
        <a
            onClick={() => {
                window.umami?.track("Tweet Component - Click", {
                    link: props.link,
                });
            }}
            href={props.link}
            target="_blank"
            rel="noreferrer"
        >
            <div className="w-full max-w-md bg-white dark:bg-gray-800 shadow-lg shadow-zinc-200 rounded-lg border dark:border-gray-700 dark:shadow-[#00000080]">
                <div className="p-6 flex gap-4">
                    <div className="shrink-0 w-12 h-12 rounded-full overflow-hidden">
                        <img alt={props.handle} src={props.image} />
                    </div>
                    <div className="flex-1 grid gap-2">
                        <div className="flex flex-col gap-0">
                            <div className="font-bold text-blue-500 flex items-center">
                                {props.name}&nbsp;
                                {props.hasCheck && (
                                    <CheckBadgeIcon className="h-5 w-5 fill-blue-500" />
                                )}
                            </div>
                            <div className="text-gray-500 dark:text-gray-400 text-sm">
                                {props.handle}
                            </div>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </a>
    );
}

function BadgeIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
        </svg>
    );
}

function ContactIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M17 18a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2" />
            <rect width="18" height="18" x="3" y="4" rx="2" />
            <circle cx="12" cy="10" r="2" />
            <line x1="8" x2="8" y1="2" y2="4" />
            <line x1="16" x2="16" y1="2" y2="4" />
        </svg>
    );
}

function HeartIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
        </svg>
    );
}

function TwitterIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
        </svg>
    );
}
