import Link from "next/link";

export default function Cta(props) {
    return (
        <div className="text-center py-16">
            <div className="text-gray-700 dark:text-gray-300 mb-6 text-xl">
                Create your own SVG vector images with AI
            </div>
            <div className="inline">
                <Link href="/create">
                    <button
                        type="button"
                        className="items-center font-bold bg-secondary-dark disabled:bg-gray-500 inline-flex justify-center rounded-md border px-4 py-2 text-base text-white  focus:outline-none sm:col-start-2 sm:text-sm shadow-md shadow-gray-100 hover:bg-secondary-darkest dark:shadow-none dark:hover:bg-secondary-darkest dark:border-0"
                    >
                        <span className="text-lg mr-2 sparkle w-4 fill-white text-white">
                            <img alt="Get started" width="16" height="15" src="/sparkle-white.svg" />
                        </span>
                        <div>Get started</div>
                    </button>
                </Link>
            </div>
        </div>
    );
}
