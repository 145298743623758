/* eslint-disable @next/next/no-img-element */
import { useContext } from "react";
import path from "path";
import { serialize } from "next-mdx-remote/serialize";
import { MDXRemote } from "next-mdx-remote";

import Header from "../../components/Header";
import UserContext from "../../components/UserContext";
import Footer from "../../components/Footer";
import Cta from "../../components/Cta";
import Link from "next/link";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

import ImageGallery from "../../components/blog/ImageGallery";
import ImageComparisonZoom from "../../components/blog/ImageComparisonZoom";
import BlogPostPreview from "../../components/BlogPostPreview";
import { getPosts } from "../../services/data";

export const shortDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const suffix = getOrdinalSuffix(date.getDate());
    const options = { day: "numeric", year: "numeric", month: "long" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate.replace(day, `${day}${suffix}`);
};

function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return "th"; // handles special cases
    switch (day % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

export default function BlogPost(props) {
    const { user, supabase } = useContext(UserContext);
    const { mdxSource } = props;
    return (
        <div className="w-full h-full dark:bg-gray-950">
            <div className="relative border-b border-gray-100 dark:border-gray-900">
                <div className="container mx-auto">
                    <Header
                        className="secondary"
                        {...props}
                        supabase={supabase}
                        user={user}
                        hideLogin={true}
                        title={mdxSource.frontmatter.title || "Blog"}
                        description={mdxSource.frontmatter.description}
                        highlightCreateButton={true}
                    />
                </div>
            </div>
            <div className="containermt-4 container mx-auto mt-6 my-2 cursor-pointer font-semibold text-gray-600 dark:text-gray-400">
                <Link href="/blog">
                    <div className="mx-4 sm:mx-16 flex items-center">
                        <ChevronLeftIcon className="h-4 mr-0.5" />
                        Blog
                    </div>
                </Link>
            </div>
            <div className="flex flex-col space-x-4 mt-6 mx-4 container lg:max-w-screen-md sm:mx-auto md:mx-auto items-center justify-center text-gray-900 dark:text-gray-100">
                <h1 className="text-center text-2xl md:text-4xl font-bold font-serif my-4 sm:my-8">
                    {mdxSource.frontmatter.title}
                </h1>
                <h2 className="text-gray-500 dark:text-gray-400">
                    {mdxSource.frontmatter.author}{" "}
                    <span className="text-gray-200 mx-2 text-sm">▪</span>{" "}
                    {shortDate(mdxSource.frontmatter.date)}
                </h2>
                {mdxSource.frontmatter.thumbnail ? (
                    <div className="rounded-md overflow-hidden h-32 sm:h-64 xl:h-96 shadow-lg my-6 flex items-center justify-center">
                        <img
                            className="mt-28"
                            alt={mdxSource.frontmatter.title}
                            src={mdxSource.frontmatter.thumbnail}
                        />
                    </div>
                ) : null}
                <div className="blog-post w-full sm:px-8 leading-2 text-gray-900 dark:text-gray-100 [&>svg]:my-4 [&>p]:mt-6 dark:[&>*>code]:bg-gray-800 [&>code]:whitespace-break-spaces [&>*>code]:p-1 [&>*>code]:rounded-md [&>*>*>*>a]:text-secondary [&>*>code]:text-secondary-dark [&>h1]:text-2xl [&>h1]:mt-6 [&>h1]:-mb-3 [&>h2]:text-xl [&>h2]:mt-6 [&>h3]:text-xl [&>h3]:mt-6  [&>h1]:font-semibold [&>h2]:font-medium [&>h2]:-mb-2 [&>*>a]:text-secondary  [&>*>*>a]:text-secondary [&>ul]:list-disc [&>ul]:mx-8 [&>ul>li]:my-4">
                    <MDXRemote {...mdxSource} components={{ ImageGallery, ImageComparisonZoom }} />
                </div>
                <div>
                    <Cta />
                </div>
                {props.otherPosts.length > 0 && (
                    <div className="mt-12 mb-6">
                        <div className="text-gray-500 text-center mb-3 dark:text-gray-400">
                            More from the blog
                        </div>
                        {props.otherPosts.map((post) => (
                            <BlogPostPreview key={post.slug} {...post} />
                        ))}
                    </div>
                )}
                <div className="text-center mt-6 mb-6">
                    <Link href="/blog">
                        <button
                            type="button"
                            className="items-center font-bold bg-white dark:bg-gray-700 dark:text-gray-100 dark:shadow-none dark:border-0 dark:hover:bg-gray-600 disabled:bg-gray-500 inline-flex justify-center rounded-md border px-4 py-2 text-base text-black focus:outline-none sm:col-start-2 sm:text-sm shadow-md shadow-gray-100 hover:bg-gray-100 "
                        >
                            <div>📖&nbsp;&nbsp;Read more</div>
                        </button>
                    </Link>
                </div>
            </div>
            <div className="mt-32">
                <Footer />
            </div>
        </div>
    );
}

export async function getStaticPaths() {
    const posts = require("fs").readdirSync(
        path.join(process.cwd(), "content/blog")
    );
    const paths = posts.map((post) => ({
        params: { id: post, slug: [post.split(".md")[0]] },
    }));
    return { paths, fallback: false };
}

export async function getStaticProps(context) {
    const post = context.params.slug.join(" ");
    let fileName = path.join(process.cwd(), "content/blog", post + ".md");
    if (require("fs").existsSync(fileName + "x")) {
        fileName += "x";
    }
    const fs = require("fs");
    const fileContents = fs.readFileSync(fileName, "utf8");
    const mdxSource = await serialize(fileContents, { parseFrontmatter: true });

    const posts = await getPosts();
    const otherPosts = posts
        .filter((p) => p.id !== post)
        .sort(() => 0.5 - Math.random());

    return {
        props: {
            mdxSource,
            otherPosts: otherPosts.slice(0, 3).map((post) => ({
                ...post,
                url: `/blog/${post.slug}`,
            })),
        },
    };
}
