import { useContext, useEffect, useRef, useState } from "react";
import Tilt from "react-parallax-tilt";

import MasonryImageList from "./MasonryImageList";
import { useRouter } from "next/router";
import Cta from "./Cta";
import Header from "./Header";
import Footer from "./Footer";
import { SearchBar } from "./SearchBar";
import SearchTag from "./SearchTag";
import { FaqBody } from "./Faq";
import GetStarted from "./GetStarted";
import UserContext from "./UserContext";
import Stars from "./Stars";
import Link from "next/link";
import ItemsProvider from "./ItemsProvider";
import { Spinner } from "./Spinner";
import Features from "./Features";
import TweetComponent from "./TweetComponent";
import BlogPostPreviews from "./BlogPostPreviews";
import CollectionsPreview from "./CollectionsPreview";
import ItemDialog from "./ItemDialog";

const exampleQueries = [
    "people illustration",
    "company logo",
    "octopus",
    "cowboy hat",
    "abstract",
    "cute bear",
    "sunset",
    "night sky",
    "tree logo",
    "eagle icon",
    "wedding",
    "cake",
];

const categories = require("../categories.json")
    .categories.filter((category) => category.image)
    .slice(0, 12);

export default function IndexLayout(props) {
    const router = useRouter();
    const { user, supabase } = useContext(UserContext);
    const [selectedExampleQueries, setSelectedExampleQueries] = useState([]);
    const [selectedItem, setSelectedItem] = useState(props.initialItem);
    const [createLoading, setCreateLoading] = useState(false);
    const itemsProviderRef = useRef(new ItemsProvider(props.initialImages));

    const [playing, setPlaying] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        const startIndex = Math.max(
            0,
            Math.round(Math.random() * (exampleQueries.length - 4))
        );
        setSelectedExampleQueries(
            exampleQueries.slice(startIndex, startIndex + 4)
        );
    }, []);

    useEffect(() => {
        let observer;
        if (!playing && videoRef.current) {
            observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach(function (entry) {
                        if (entry.isIntersecting) {
                            videoRef.current?.play();
                            setPlaying(true);
                        }
                    });
                },
                {
                    threshold: 0.3,
                }
            );
            observer.observe(videoRef.current);
        }
        return () => {
            observer?.disconnect();
        };
    }, [videoRef]);

    useEffect(() => {
        if (user && props.redirect) {
            router.replace(props.redirect);
        }
    }, [user, props.redirect, router]);

    return (
        <div className="dark:bg-gray-900">
            <div className="overflow-hidden dark:overflow-visible relative bg-gradient-to-b from-primary-superdark to-primary dark:from-primary-superdark dark:to-gray-900 pb-8 border-b border-gray-200 dark:border-0 z-10">
                <Stars />
                <div className="">
                    <Header
                        {...props}
                        supabase={supabase}
                        main={true}
                        user={user}
                        hideAvatar={true}
                    />
                    {props.headerContent ? (
                        props.headerContent
                    ) : (
                        <div className="flex flex-col sm:flex-row mx-4 my-32 mb-8 sm:mb-16 sm:mx-8 lg:mx-32 xl:mx-48 2xl:mx-64 mt-16 sm:mt-16 text-3xl lg:text-2xl xl:text-5xl text-white font-semibold text-left">
                            <div className="flex flex-1 flex-row order-2 sm:order-1 z-10">
                                <div className="sm:mt-0">
                                    <h1
                                        className="title mt-6 sm:mt-0 sm:mx-8 text-center sm:text-left my-8 sm:my-0 "
                                        style={{ lineHeight: "1.25em" }}
                                    >
                                        Create vector images with AI
                                    </h1>
                                    <div className="sm:mx-8 mt-2 sm:mt-6 text-lg font-light">
                                        Use the power of generative AI to create{" "}
                                        <span className=" text-white px-1 bg-[#e26447] dark:bg-[#ca624a]">
                                            infinitely scalable
                                        </span>{" "}
                                        vector images, logos, icons and
                                        illustrations for your website, business
                                        or app.
                                    </div>
                                    <div className="sm:mx-8 mt-6">
                                        <div className="inline-block">
                                            <Link href="/create">
                                                <button
                                                    onClick={() =>
                                                        setCreateLoading(true)
                                                    }
                                                    type="button"
                                                    className="text-xl items-center font-bold bg-secondary-dark disabled:bg-gray-500 inline-flex justify-center rounded-md px-8 py-3 text-white  focus:outline-none sm:col-start-2 sm:text-sm hover:bg-secondary-darkest"
                                                >
                                                    {createLoading ? (
                                                        <div className="-ml-1 -mt-1 -mb-1 mr-1 w-6 h-6">
                                                            <Spinner />
                                                        </div>
                                                    ) : (
                                                        <span className="text-lg mr-2 sparkle w-4 fill-white text-white">
                                                            <img
                                                                alt="Create now"
                                                                width="16"
                                                                height="15"
                                                                src="/sparkle-white.svg"
                                                            />
                                                        </span>
                                                    )}
                                                    <div>Create now</div>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative flex-1 order-1 sm:order-2 flex justify-center sm:ml-2 pointer-events-none">
                                <div className="sm:-mb-64">
                                    <Tilt
                                        perspective={500000}
                                        glareEnable={false}
                                        transitionSpeed={5000}
                                        gyroscope={true}
                                        glareMaxOpacity={0}
                                        reset={false}
                                        glarePosition="top"
                                        tiltReverse={true}
                                        scale={1}
                                        trackOnWindow={true}
                                        className="relative"
                                        tiltMaxAngleX={14}
                                        tiltMaxAngleY={14}
                                        style={{
                                            transformStyle: "preserve-3d",
                                        }}
                                    >
                                        <div className="rounded-full overflow-hidden circle-shadow w-[128px] sm:w-[300px] lg:w-[300px]">
                                            <img
                                                src="/circle-bg-sm.png"
                                                alt="VectorArt.ai"
                                                width="100%"
                                            />
                                        </div>
                                        <div
                                            className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
                                            style={{
                                                transform: "translateZ(60px)",
                                            }}
                                        >
                                            <img
                                                src="/circle-fg-sm.png"
                                                alt="VectorArt.ai"
                                                width="100%"
                                            />
                                        </div>
                                    </Tilt>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="bg-ggray-100 dark:bg-gray-900 dark:text-gray-200">
                <div className="">
                    <div className="lg:px-32 bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-700">
                        <GetStarted />
                    </div>
                    <div className="lg:px-32 pb-12 pt-8 border-b border-gray-200 dark:border-gray-700 flex flex-col justify-center bg-white dark:bg-gray-800">
                        <div className="w-full p-4 h-full -mt-8 sm:mt-0 mb-6 sm:mb-8 flex justify-center">
                            <div className="rounded-md sm:overflow-hidden sm:h-96 shadow sm:shadow-lg">
                                <video
                                    loop
                                    muted
                                    playsInline
                                    className="w-full h-full rounded-xl sm:overflow-hidden"
                                    ref={videoRef}
                                >
                                    <source
                                        src="/videos/intro-720p.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                        <Features />
                    </div>
                    <div className="lg:px32 pt-12">
                        <Link href="/collection">
                            <h2 className="font-serif cursor-pointer text-2xl text-center font-light sm:mx-20 py-4 lg:pb-8 lg:mx-16 xl:mx-32 lg:text-4xl text-gray-900 dark:text-gray-100 no-underline">
                                Illustration Sets
                            </h2>
                        </Link>
                        <CollectionsPreview />
                    </div>
                    <div className="lg:px-32 text-center pb-12 mt-6">
                        <Link href="/collection">
                            <button
                                type="button"
                                className="items-center font-bold bg-white dark:bg-gray-700 dark:border-0 dark:hover:bg-gray-800 disabled:bg-gray-500 inline-flex justify-center rounded-md border px-4 py-2 text-base text-black focus:outline-none sm:col-start-2 sm:text-sm shadow-md shadow-gray-100 dark:shadow-none hover:bg-gray-200 dark:text-white"
                            >
                                <div>All illustration sets&nbsp;&nbsp;📚</div>
                            </button>
                        </Link>
                    </div>
                    <div className="lg:px-32 2xl:px-72 bg-white dark:bg-gray-800 border-b border-t dark:border-gray-700">
                        <div className="text-center pt-12">
                            <Link href="/browse">
                                <h2 className="font-serif cursor-pointer text-2xl text-center font-light sm:mx-20 py-4 lg:pb-8 lg:mx-16 xl:mx-32 lg:text-4xl text-gray-900 dark:text-gray-100 no-underline">
                                    Browse Free Library
                                </h2>
                            </Link>
                        </div>
                        <div className="md:flex md:items-center mt-8 sm:my-8 justify-center sm:mx-8">
                            <div className="flex-1 mx-3 sm:mx-8 md:mx-32 lg:mx-64">
                                <SearchBar
                                    initialValue={props.query}
                                    onSetQuery={(q) => {
                                        q &&
                                            q.length > 0 &&
                                            router.push(
                                                "/search?q=" +
                                                    encodeURIComponent(q)
                                            );
                                    }}
                                />
                                <div className="mt-1 text-xs">
                                    {selectedExampleQueries.map((query, i) => (
                                        <SearchTag key={query} query={query} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="w-full ">
                            <MasonryImageList
                                className=""
                                limit={32}
                                canLoadMore={false}
                                initialQuery={""}
                                query={""}
                                baseUrl="/browse"
                                itemsProvider={itemsProviderRef.current}
                                onItemClick={(item, i) => {}}
                            />
                        </div>
                        <div className="px-8 lg:px-32 my-6 w-full h-full grid grid-cols-2 xl:grid-cols-3">
                            {categories.map((item, i) => (
                                <Link
                                    key={item.name}
                                    href={`/category/${item.name}`}
                                >
                                    <a>
                                        <div
                                            className="text-secondary-dark dark:text-secondary-light"
                                            key={item.name}
                                        >
                                            {item.displayName} category
                                        </div>
                                    </a>
                                </Link>
                            ))}
                        </div>
                        <div className="text-center py-6 pb-12">
                            <Link href="/browse">
                                <button
                                    type="button"
                                    className="items-center font-bold bg-secondary-dark disabled:bg-gray-500 inline-flex justify-center rounded-md border px-4 py-2 text-base text-white focus:outline-none sm:col-start-2 sm:text-sm shadow-md shadow-gray-100 hover:bg-secondary-darkest dark:shadow-none dark:border-0"
                                >
                                    <div>Browse more&nbsp;&nbsp;🔥</div>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="lg:px-32 border-b border-gray-200 dark:border-gray-700">
                        <div className="mt-8 mb-4 text-center">
                            <Link href="/blog">
                                <h2 className="font-serif cursor-pointer text-2xl text-center font-light sm:mx-20 py-4 lg:pb-8 lg:mx-16 xl:mx-32 lg:text-4xl text-gray-800 dark:text-gray-100 no-underline">
                                    Our Blog
                                </h2>
                            </Link>
                        </div>
                        <div className="flex flex-col flex-wrap md:flex-row 2xl:mx-2 mb-2 justify-center">
                            <div className="mx-auto 2xl:mx-2 mb-8 flex flex-col [&>*]:my-4 items-center md:place-content-center lg:inline-grid grid-cols-2">
                                <BlogPostPreviews />
                            </div>
                        </div>
                        <div className="text-center pb-12">
                            <Link href="/blog">
                                <button
                                    type="button"
                                    className="items-center font-bold bg-white dark:bg-gray-700  dark:hover:bg-gray-800 disabled:bg-gray-500 inline-flex justify-center rounded-md border px-4 py-2 text-base text-black focus:outline-none sm:col-start-2 sm:text-sm shadow-md shadow-gray-100 dark:shadow-none dark:border-0 hover:bg-gray-200 dark:text-white"
                                >
                                    <div>📖&nbsp;&nbsp;Read more</div>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="py-12 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 pb-20">
                        <div className="lg:px-64 xl:px-72 mx-auto max-w-7xl">
                            <h2 className="font-serif cursor-pointer text-2xl text-center font-light sm:mx-20 py-4 lg:pb-8 lg:mx-16 xl:mx-32 lg:text-4xl text-gray-900 dark:text-gray-100 no-underline">
                                Frequently Asked Questions
                            </h2>
                            <FaqBody />
                        </div>
                    </div>
                    <div className="py-12">
                        <h2 className="font-serif text-2xl text-center font-light sm:mx-20 py-4 lg:pb-8 lg:mx-16 xl:mx-32 lg:text-4xl text-gray-900 dark:text-gray-100 no-underline">
                            What Our Users Are Saying
                        </h2>
                    </div>
                    <div className="pb-12 px-4 lg:px-24 xl:px-32 xl:mx-64 gap-6 flex flex-col sm:flex-row justify-center items-center -mb-44">
                        <div className="flex flex-col gap-6">
                            <TweetComponent
                                handle="@rauchg"
                                name="Guillermo Rauch"
                                image="/images/tweets/rauchg.jpg"
                                link="https://twitter.com/rauchg/status/1728157355852845504"
                                hasCheck={true}
                            >
                                <p className="text-sm leading-relaxed">
                                    AI-generated vector SVG images and icons.
                                </p>
                                <p className="text-sm leading-relaxed">
                                    I&apos;ve literally been waiting for this
                                    product to drop since the whole AI wave
                                    started 😍
                                </p>
                                <p className="text-sm leading-relaxed text-blue-500">
                                    vectorart.ai
                                </p>
                            </TweetComponent>
                            <TweetComponent
                                handle="@SodaPrompt"
                                name="Crystal"
                                image="/images/tweets/soda.jpg"
                                link="https://twitter.com/SodaPrompt/status/1728288303936635091"
                                hasCheck={false}
                            >
                                <p className="text-sm leading-relaxed">
                                    Well this is exciting. Vectors! Yeah baby!!!
                                    #aiart made in &nbsp;
                                    <span className="text-sm leading-relaxed text-blue-500">
                                        vectorart.ai
                                    </span>
                                </p>
                            </TweetComponent>
                            <div className="hidden sm:block quickly-faded  pointer-events-none">
                                <TweetComponent
                                    handle="@SodaPrompt"
                                    name="Crystal"
                                    image="/images/tweets/soda.jpg"
                                    link="#"
                                    hasCheck={false}
                                >
                                    <p className="text-sm leading-relaxed">
                                        Well this is exciting. Vectors! Yeah
                                        baby!!! #aiart made in &nbsp;
                                        <span className="text-sm leading-relaxed text-blue-500">
                                            vectorart.ai
                                        </span>
                                    </p>
                                </TweetComponent>
                            </div>
                        </div>
                        <div className="flex flex-col gap-6 sm:mt-0">
                            <TweetComponent
                                handle="@jwage"
                                name="Jonathan H. Wage"
                                image="/images/tweets/jwage.jpg"
                                link="https://twitter.com/jwage/status/1728162221153309157"
                                hasCheck={true}
                            >
                                <p className="text-sm leading-relaxed">
                                    This service looks great &nbsp;
                                    <span className="text-sm leading-relaxed text-blue-500">
                                        vectorart.ai
                                    </span>
                                </p>
                            </TweetComponent>
                            <TweetComponent
                                handle="@mikesammartano"
                                name="Mike Sammartano"
                                image="/images/tweets/mike.jpg"
                                link="https://twitter.com/mikesammartano/status/1718309886633693587"
                                hasCheck={false}
                            >
                                <p className="text-sm leading-relaxed">
                                    Playing around with #AI SVG generators
                                    today...used{" "}
                                    <span className="text-blue-500">
                                        vectorart.ai
                                    </span>{" "}
                                    to create an SVG, brought it in to #Keynote,
                                    made some edits and some cool #MagicMove
                                    transitions!
                                </p>
                            </TweetComponent>
                            <div className="quickly-faded pointer-events-none">
                                <TweetComponent
                                    handle="@mikesammartano"
                                    name="Mike Sammartano"
                                    image="/images/tweets/mike.jpg"
                                    link="#"
                                    hasCheck={false}
                                >
                                    <p className="text-sm leading-relaxed">
                                        Playing around with #AI SVG generators
                                        today...used{" "}
                                        <span className="text-blue-500">
                                            vectorart.ai
                                        </span>{" "}
                                        to create an SVG, brought it in to
                                        #Keynote, made some edits and some cool
                                        #MagicMove transitions!
                                    </p>
                                </TweetComponent>
                            </div>
                        </div>
                    </div>
                    <Cta />
                </div>
                {selectedItem && !props.headerContent && (
                    <ItemDialog
                        supabase={supabase}
                        item={selectedItem}
                        onClose={() => {
                            router.replace(
                                router.asPath.split("/").slice(0, -1).join("/"),
                                undefined,
                                {
                                    shallow: true,
                                }
                            );
                            setSelectedItem(null);
                        }}
                    />
                )}
            </div>
            <Footer {...props} user={user} />
        </div>
    );
}
