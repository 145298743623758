
import { CheckIcon } from "@heroicons/react/20/solid";

const features = [[
    "Create Custom SVG Images",
    "Write Text-to-Image Prompts",
    "Draw Sketch-to-Image Prompts",
    "Explore Illustration Styles"], [
    "Royalty-Free Usage",
    "Inspirational Free Image Gallery",
    "Try for Free / Monthly Subscription",
    "Cancel Anytime",
]]

export default function Features() {
    return (
        <div className="flex flex-wrap self-center flex-col sm:flex-row">
            <div className="dark:text-white mr-6 sm:mr-10 lg:mr-12 font-medium">
                VectorArt Features
            </div>
            {features.map((featureList, i) => (
                <div className="ml-6 sm:ml-10 lg:ml-12 mt-3 sm:mt-0 text-sm" key={`feature-list-${i}`}>
                    <ol>
                        {featureList.map((feature, j) => (
                            <li className="mb-2 flex items-start" key={`feature-${i}-${j}`}><CheckIcon className="w-5 mr-2 fill-secondary" /><div>{feature}</div></li>
                        ))}
                    </ol>
                </div>
            ))}
        </div>
    )
}
