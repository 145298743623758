import Link from "next/link";
import { NewLabel } from "./LibraryLayout";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function CollectionItem(props) {
    const { collectionSlug, collectionName, images } = props;
    return (
        <Link href={`/collection/${collectionSlug}`}>
            <a>
                <div
                    className={classNames(
                        props.size === "small"
                            ? "w-64 mx-3 mb-6"
                            : "w-72 mx-6 mb-12",
                        "hover:bg-white dark:hover:bg-gray-800 dark:bg-gray-900 hover:shadow-md group hover:border p-4 transition-all rounded-lg hover:border-gray-300 hover:shadow-gray-300 dark:hover:shadow-gray-700 dark:border-0 dark:hover:border-0 dark:hover:shadow-none dark:text-white"
                    )}
                >
                    <div className="grid grid-cols-2 grid-rows-2 rounded-md overflow-hidden">
                        {images.map(({ url }) => {
                            return (
                                <img
                                    key={url}
                                    src={url.replace("/sm", "/xxs")}
                                    alt={collectionName}
                                />
                            );
                        })}
                    </div>
                    <div className="text-center font-medium text-gray-700 dark:text-gray-200 text-lg mt-4 group-hover:text-black dark:group-hover:text-white group-hover:underline decoration-secondary-dark underline-offset-4">
                        {collectionName}
                        {props.new ? <NewLabel /> : null}
                    </div>
                </div>
            </a>
        </Link>
    );
}
