export default function SearchTag(props) {
    return (
        <a href={"/search?q=" + encodeURI(props.query)}>
            <div className="inline-block px-2 py-1 rounded bg-white bg-opacity-20 text-white opacity-80 cursor-pointer mr-2 lg:mr-3 mt-2">
                <div className="flex items-center">
                    <svg
                        aria-hidden="true"
                        className="w-3 h-3 text-white -mb-0.5 mr-1"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                    </svg>

                    {props.query}
                </div>
            </div>
        </a>
    );
}
