import { useEffect, useState } from "react";

export function SearchBar(props) {
    const [inputValue, setInputValue] = useState(props.initialValue || "");
    useEffect(() => {
        if (!inputValue) {
            props.onSetQuery?.("");
        }
    }, [inputValue, props]);
    return (
        <form
            className="flex-1"
            onSubmit={(evt) => {
                props.onSetQuery?.(inputValue);
                evt.preventDefault();
            }}
        >
            <label
                className="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
                Search
            </label>
            <div className="relative">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                    </svg>
                </div>
                <input
                    type="search"
                    autoComplete="off"
                    id="default-search"
                    defaultValue={props.initialValue}
                    className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-900 rounded-md border border-gray-300 dark:border-gray-700 outline-none dark:text-gray-100"
                    placeholder="Search for an image"
                    required
                    onChange={(evt) => setInputValue(evt.target.value)}
                />
                <button
                    type="submit"
                    className="text-white absolute right-2.5 bottom-2.5 from-secondary-dark to-secondary hover:from-secondary-darkest hover:to-secondary-dark bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                >
                    Search
                </button>
            </div>
        </form>
    );
}
