import Link from "next/link"
import Image from "next/image";

const features = [
  {
    name: 'Type a Prompt',
    description:
      'Type in a description of your desired image. Enter as many details as you can to help the AI figure out what you want.',
  },
  {
    name: 'Press Create',
    description:
      'Watch our AI engine create your vector graphic. Hang in there, it takes a few seconds.',
  },
  {
    name: 'Download',
    description:
      'Download the image to your local disk. Your SVG file can be scaled to any size and still look amazing!',
  },
]

export default function GetStarted() {
  return (
    <div className="dark:bg-transparent bg-white py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl font-serif text-2xl text-center font-light">
            Get started with a few easy steps
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-12 lg:mt-20 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature, i) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">
                  <div className="bg-primary text-white px-2 rounded-md">{i + 1}.</div>
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600 dark:text-gray-300">
                  <div className="mb-4 text-center lg:text-left">
                    <Image className="inline rounded-md border overflow-hidden" alt={feature.name} width="320" height="172" src={`/get-started-${i + 1}.png`} />
                  </div>
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="mx-auto mt-16 max-w-2xl lg:max-w-none text-center">
        <Link href="/create">
          <button
            type="button"
            className="items-center font-bold bg-secondary-dark disabled:bg-gray-500 inline-flex justify-center rounded-md border px-4 py-2 text-base text-white  focus:outline-none sm:col-start-2 sm:text-sm shadow-md shadow-gray-100 dark:shadow-gray-900 hover:bg-secondary-darkest dark:border-0"
          >
            <span className="text-lg mr-2 sparkle w-4 fill-white text-white">
              <Link href="/create"><a><img alt="Get started" width="16" height="15" src="/sparkle-white.svg" border="0"/></a></Link>
            </span>
            <div>Get started</div>
          </button>
        </Link>
      </div>
      </div>
    </div>
  )
}