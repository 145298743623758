import BlogPostPreview from "./BlogPostPreview";

export default function BlogPostPreviews(props) {
    return (
        <>
            <BlogPostPreview
                title="AI for Marketers"
                description="The practical applications of AI in marketing span various aspects of the field."
                thumbnail="/images/ai-for-marketers/image1.webp"
                url="/blog/ai-for-marketers"
            />
            <BlogPostPreview
                title="Designing a logo with AI"
                description="How you can use generative AI to create a logo for your business."
                thumbnail="/images/blog-logo.svg"
                url="/blog/designing-a-logo-with-ai"
            />
            <BlogPostPreview
                title="SVG is the Future"
                description=" In this post, we share our take on SVG and why we love it."
                thumbnail="/images/svg-future.svg"
                url="/blog/svg-is-the-future"
            />
            <BlogPostPreview
                title="Prompt Engineering for Vector Images"
                description="Prompt engineering takes on a whole new dimension when it comes to vector images."
                thumbnail="/images/prompt3.svg"
                url="/blog/prompt-engineering-vector-images"
            />
        </>
    );
}
