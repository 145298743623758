import CollectionItem from "./CollectionItem";

export default function CollectionsPreview() {
    return (
        <div
            id="scrollableDiv"
            className="w-full h-full flex flex-col  items-center justify-center"
        >
            <div className="flex items-center justify-center flex-wrap">
                <CollectionItem
                    size="small"
                    collectionName="Soft Blobs"
                    collectionSlug="soft-blobs"
                    new={true}
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4ed8b6dcaf5f53e0d311/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4d3cb281a2df8851ebe6/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4077887cf5f48be07a2d/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/40f888a59f8fbcbab5cb/xxs",
                        },
                    ]}
                />
                <CollectionItem
                    size="small"
                    collectionName="Raw Forms"
                    collectionSlug="raw-forms"
                    new={true}
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4f68b15c5859b6cdaadb/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4e729450ba096b08fee7/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4168907bda03109b89ae/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4d568a7401fa82d97aef/xxs",
                        },
                    ]}
                />
                <CollectionItem
                    size="small"
                    collectionName="Playful Strokes"
                    collectionSlug="playful-strokes"
                    new={true}
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4f5a9e26c0309ae4f215/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/48b281b7efbe609bd5f0/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4a55a930da3504798492/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/451a9a83436d210f43e0/xxs",
                        },
                    ]}
                />
            </div>
            <div className="flex items-center justify-center flex-wrap">
                <CollectionItem
                    size="small"
                    collectionName="Warm Endeavors"
                    collectionSlug="warm-endeavors"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/400fa889832b5634005f/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/438fb0e059a12d2676b9/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/46bd928f73fe8dc2db39/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4369940169d03da83afd/xxs",
                        },
                    ]}
                />

                <CollectionItem
                    size="small"
                    collectionName="Cyan Essence"
                    collectionSlug="cyan-essence"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4a47ba6b0f32f05b8b34/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/40aa9f4023c4d49794d7/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4463b7ab53b1e322fd9c/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4b16a6a53411cd95be69/xxs",
                        },
                    ]}
                />

                <CollectionItem
                    size="small"
                    collectionName="Melancholic Magic"
                    collectionSlug="melancholic-magic"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/h6iSUYfvO5REiKiAyuz_fht2-lFngT3_/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/jNuY8JuGAs5-BFcUouQY5p8VGBxOB8uF/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/x-1Jx8KLlXHLw36Do964qRdIqBaKMCBV/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/9MPYexEG3Mc00jO0BaWzM_p9yzksW22t/xxs",
                        },
                    ]}
                />
            </div>
            <div className="flex items-center justify-center flex-wrap">
                <CollectionItem
                    size="small"
                    collectionName="Stochastic Splash"
                    collectionSlug="stochastic-splash"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/430a98f0e8a0d8a19f9a/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4c3fa5d1eecfcafca9bc/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4d0daab197533da468c8/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4d8885cafdaf1ef5b487/xxs",
                        },
                    ]}
                />
                <CollectionItem
                    size="small"
                    collectionName="Liquid Elegance"
                    collectionSlug="liquid-elegance"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/dD-RXq1tFzCmBNgS-2ejwVqT2QcOIPvO/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/7Hin0C-ImZx4jSehmkl_R2pN2ZFMGELW/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/5MTJiRk3pp-GRWGLg2PU2PNRgTJUXAka/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/fw_Y2EB2doJt-rl6gTSi3Y709HBDqWN3/xxs",
                        },
                    ]}
                />

                <CollectionItem
                    size="small"
                    collectionName="Geometric Grace"
                    collectionSlug="geometric-grace"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/Vw2tcnJWrvMZuLDVz-1D9wiap9kKs0BQ/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/z73tACeYUbPTMN6WzEgNJzGCgz6d_smb/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/ieT9RYdQBs2494HDkL6NvHjR7AuwH1-Y/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/8CdDitBbxPW1gAE34R-m7E7Iz-_GPa95/xxs",
                        },
                    ]}
                />
            </div>
        </div>
    );
}
