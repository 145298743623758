export default function Stars(props) {
    return (
        <div className="absolute w-full h-full opacity-20 pointer-events-none">
            <svg className="w-full h-full">
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(600 -233)"
                    style={{ fill: "#FF0080" }}
                    className="opacity-20"
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(100 -213)"
                    style={{ fill: "#FF0080" }}
                    className="opacity-50"
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-1000 -223)"
                    style={{ fill: "#FF0080" }}
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-800 -203)"
                    style={{ fill: "#ffffff" }}
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-200 -253)"
                    style={{ fill: "#ffffff" }}
                    className="opacity-50"
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-650 -233)"
                    style={{ fill: "#ffffff" }}
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-0.01 -253)"
                    style={{ fill: "#ffffff" }}
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(630 -213)"
                    style={{ fill: "#FF0080" }}
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(180 -223)"
                    style={{ fill: "#ffffff" }}
                    className="opacity-50"
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-1100 -203)"
                    style={{ fill: "#FF0080" }}
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-700 -103)"
                    style={{ fill: "#FF0080" }}
                    className="opacity-50"
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-840 -383)"
                    style={{ fill: "#FF0080" }}
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-780 -263)"
                    style={{ fill: "#FF0080" }}
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-0.01 -253)"
                    style={{ fill: "#FF0080" }}
                    className="opacity-50"
                />
                <path
                    d="M1023.8,488.4a8.6,8.6,0,0,0-1.6-.1h-.3c0-.3-.1-.6-.1-1.1-.1-.7-.3-2-1.5-2.2-.2,0-.6,1.4-.7,1.5a8.75,8.75,0,0,0-.2,1.6v.2c-.3,0-.7.1-1.1.1-.7.1-2.2.2-2.4,1.5,0,.2,1.5.6,1.6.6a12.25,12.25,0,0,0,1.9.2c0,.3.1.6.1.9.1.7.2,2.1,1.4,2.3.2,0,.6-1.4.7-1.5a9.91,9.91,0,0,0,.2-1.8,5.28,5.28,0,0,0,1.2-.1c.7-.1,2.1-.3,2.3-1.5C1025.4,489,1024,488.5,1023.8,488.4Z"
                    transform="translate(-30 -599)"
                    style={{ fill: "#FF0080" }}
                />
            </svg>
        </div>
    );
}
