import React, { useState } from "react";

const ComparisonImage = ({
    type,
    content,
    mousePosition,
    isHovering,
    getZoomStyles,
    onMouseMove,
    zoomScale,
}) => {
    return (
        <div className="relative w-48 h-48 border" onMouseMove={onMouseMove}>
            <img
                src={content}
                alt={`${type} image`}
                className="w-full h-full object-cover"
            />

            {isHovering && (
                <div
                    className="absolute w-32 h-32 border-4 border-blue-500 rounded-full bg-white shadow-lg overflow-hidden"
                    style={{
                        ...getZoomStyles(mousePosition.x, mousePosition.y).lens,
                        pointerEvents: "none",
                        zIndex: 50,
                    }}
                >
                    <div
                        style={
                            getZoomStyles(mousePosition.x, mousePosition.y)
                                .source
                        }
                    >
   
                            <img
                                src={content}
                                alt={`Zoomed ${type} image`}
                                className="w-full h-full object-cover"
                            />
                    </div>
                </div>
            )}
            <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white px-2 py-1 text-sm">
                {type === "svg" ? "SVG" : content.split(".").slice(-1)[0].toUpperCase()}
            </div>
        </div>
    );
};

const ImageComparison = ({
    svgContent,
    rasterSrc,
    description = "Notice how the SVG image remains crisp and sharp at any zoom level, while the raster image becomes pixelated when magnified.",
    zoomScale = 5,
}) => {
    const [mousePosition, setMousePosition] = useState({ x: 96, y: 96 });
    const [isHovering, setIsHovering] = useState(true);
    const [primaryImage, setPrimaryImage] = useState(null);

    const handleMouseEnter = (type) => {
        setIsHovering(true);
        setPrimaryImage(type);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
        setPrimaryImage(null);
    };

    const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setMousePosition({ x, y });
    };

    const getZoomStyles = (mouseX, mouseY) => {
        const lensSize = 128; // 128px x 128px lens
        const imageSize = 192; // 192px x 192px source image
        const scale = zoomScale; // Use zoomScale prop

        // Calculate lens position
        const lensLeft = mouseX - lensSize / 2;
        const lensTop = mouseY - lensSize / 2;

        // Calculate the position where the mouse is pointing in the zoomed space
        const zoomedX = mouseX * scale;
        const zoomedY = mouseY * scale;

        // Center the zoomed content in the lens
        const sourceX = zoomedX - lensSize;
        const sourceY = zoomedY - lensSize;

        return {
            lens: {
                left: `${lensLeft}px`,
                top: `${lensTop}px`,
            },
            source: {
                position: "absolute",
                width: `${imageSize * scale}px`,
                height: `${imageSize * scale}px`,
                left: `${-sourceX}px`,
                top: `${-sourceY}px`,
            },
        };
    };

    return (
        <div>
            <div className="flex gap-4 justify-around relative p-8">
                <ComparisonImage
                    type="svg"
                    content={svgContent}
                    mousePosition={mousePosition}
                    isHovering={isHovering}
                    getZoomStyles={getZoomStyles}
                    onMouseMove={handleMouseMove}
                    zoomScale={zoomScale}
                />

                <ComparisonImage
                    type="raster"
                    content={rasterSrc}
                    mousePosition={mousePosition}
                    isHovering={isHovering}
                    getZoomStyles={getZoomStyles}
                    onMouseMove={handleMouseMove}
                    zoomScale={zoomScale}
                />
            </div>

            <div className="text-sm text-gray-600 text-center space-y-2">
                <p>
                    Move your cursor over and around the images to see
                    the magnified views.
                </p>
                <p>{description}</p>
            </div>
        </div>
    );
};

// Example usage with default star SVG
export const starSvg = `
  <svg viewBox="0 0 100 100">
    <path d="M50 0 L61 35 L97 35 L68 57 L79 91 L50 70 L21 91 L32 57 L3 35 L39 35 Z" 
      fill="gold" stroke="orange"/>
  </svg>
`;

export default ImageComparison;
