import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
    {
        question: "What are vector graphics?",
        answer: "Vector graphics are images created using mathematical formulas, which allows them to remain pixel perfect and be scaled without losing quality.",
    },
    {
        question: "What is an SVG?",
        answer: "SVG stands for Scalable Vector Graphics. It's an XML-based vector image file format for two-dimensional graphics. SVG's can be scaled up or down in size without losing any quality or clarity. SVG supports interactivity and animation, making it a versatile choice for many different types of graphics and web design applications. ",
    },
    {
        question: "What is a PNG?",
        answer: "PNG stands for Portable Network Graphics. It is a raster graphics file format that supports lossless data compression. Unlike JPEG, which loses quality each time it's compressed and decompressed, PNG retains the exact quality of the image. PNG supports transparency and is most commonly used for web graphics.",
    },
    {
        question: "Can I use the images on this site for free?",
        answer: "Yes, you can use images on this site for free. We offer a free tier that allows you to download and use a certain number of images without any cost and used for personal or educational use.",
    },
    {
        question: "Can I use the images on this site for commercial purposes?",
        answer: "Yes, when you subscribe for any of the VectorArt.ai paid plans, you can use any image you download or create for commercial purposes. You can use the images for your website, social media, marketing, advertising, and more.",
    },
    {
        question: "How is the payment handled?",
        answer: "Payment is securely processed through our payment gateway provider, Stripe. All transactions are encrypted for your safety.",
    },
    {
        question: "What if I want to cancel my subscription?",
        answer: "You can cancel your subscription at any time from your account settings page. If you cancel, your subscription will remain active until the end of the current billing cycle, but it will not renew for the next cycle.",
    },
    {
        question: "What if I want to change my subscription?",
        answer: "You can upgrade or downgrade your subscription plan at any time from your account settings page. If you upgrade, the new features will become available immediately and the difference in cost will be charged. ",
    },
];

export function FaqBody() {
    return (
        <dl className="mt-10 mx-6 space-y-6 divide-y divide-gray-900/10 dark:divide-gray-700">
            {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                        <>
                            <dt>
                                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900 dark:text-white">
                                    <span className="text-base font-semibold leading-7">
                                        {faq.question}
                                    </span>
                                    <span className="ml-6 flex h-7 items-center">
                                        {open ? (
                                            <MinusSmallIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <PlusSmallIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </span>
                                </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel
                                static
                                as="dd"
                                className="mt-2 pr-12"
                            >
                                <p className={`text-base leading-7 text-gray-600 dark:text-gray-300 ${open ? "block" : "hidden"}`}>
                                    {faq.answer}
                                </p>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            ))}
        </dl>
    );
}

export default function Faq() {
    return (
        <div className="" id="faq">
            <div className="mx-auto max-w-7xl md:px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-2xl text-center sm:text-left font-bold leading-10 tracking-tight text-gray-900 dark:text-white">
                        Frequently asked questions
                    </h2>
                    <FaqBody />
                </div>
            </div>
        </div>
    );
}
